export default [
	// 历史单据补录第二步
	{
		path: 'historicalBillAddStepTwo',
		name: 'historicalBillAddStepTwo',
		component: () => import('@/views/crmManage/historicalBill/historicalBillAdd/stepTwo.vue'),
	},
	// 历史单据补录第一步
	{
		path: 'historicalBillAddStepOne',
		name: 'historicalBillAddStepOne',
		component: () => import('@/views/crmManage/historicalBill/historicalBillAdd/stepOne.vue'),
	},
	// 历史单据补录第三步
	{
		path: 'historicalBillAddStepThree',
		name: 'historicalBillAddStepThree',
		component: () => import('@/views/crmManage/historicalBill/historicalBillAdd/stepThree.vue'),
	},
	// 历史单据补录第四步
	{
		path: 'historicalBillAddStepFour',
		name: 'historicalBillAddStepFour',
		component: () => import('@/views/crmManage/historicalBill/historicalBillAdd/stepFour.vue'),
	},
	// 历史单据补录第四步
	{
		path: 'historicalBillAddStepFive',
		name: 'historicalBillAddStepFive',
		component: () => import('@/views/crmManage/historicalBill/historicalBillAdd/stepFive.vue'),
	},
]
