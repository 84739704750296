export default [
	// 进场详情
	{
		path: 'approachDetails',
		name: 'approachDetails',
		component: () =>
			import('@/views/rentalServiceManage/rentalService/approach/approachDetails.vue'),
	},
	// 退场详情
	{
		path: 'exitDetails',
		name: 'exitDetails',
		component: () => import('@/views/rentalServiceManage/rentalService/exit/exitDetails.vue'),
	},
]
