import RouteView from '@/views/layout/routerView.vue'
import sysDetailsRouter from './sys'
import assetsDetailsRouter from './assets'
import crmDetailsRouter from './crm'
import financeDetailsRouter from './finance'
import rentalDetailsRouter from './rental'

export default {
	path: 'flow',
	name: 'Flow',
	component: RouteView,
	children: [
		...sysDetailsRouter,
		...assetsDetailsRouter,
		...rentalDetailsRouter,
		...financeDetailsRouter,
		...crmDetailsRouter,
	],
}
