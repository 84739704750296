import RouteView from '@/views/layout/routerView.vue'

export default {
	path: 'sys',
	name: 'Sys',
	component: RouteView,
	children: [
		{
			path: 'menuPc',
			name: 'menuPc',
			component: () => import('@/views/organizationManage/menuManage/menuPc/menuPc.vue'),
		},
		{
			path: 'menuApp',
			name: 'menuApp',
			component: () => import('@/views/organizationManage/menuManage/menuApp/menuApp.vue'),
		},
		// 流程引擎
		{
			path: 'flowList',
			name: 'flowList',
			component: () => import('@/views/organizationManage/flowManage/index.vue'),
		},
		// 业务类型
		{
			path: 'flowTypeList',
			name: 'flowTypeList',
			component: () => import('@/views/organizationManage/flowManage/flowType/index.vue'),
		},
		{
			path: 'serviceList',
			name: 'serviceList',
			component: () => import('@/views/organizationManage/serviceList/serviceList.vue'),
		},
		// 角色管理
		{
			path: 'roleList',
			name: 'roleList',
			component: () => import('@/views/organizationManage/roleManage/roleList.vue'),
		},
		// 数据字典
		{
			path: 'dictionary',
			name: 'dictionary',
			component: () => import('@/views/organizationManage/dictionary/index.vue'),
		},
		// 体系职位列表
		{
			path: 'sysPost',
			name: 'sysPost',
			component: () => import('@/views/organizationManage/sysPost/index.vue'),
		},
		// 编号列表
		{
			path: 'codeNumberList',
			name: 'codeNumberList',
			component: () => import('@/views/organizationManage/codeNumber/codeNumberList.vue'),
		},
		// 编号类型列表
		{
			path: 'codeNumberType',
			name: 'codeNumberType',
			component: () => import('@/views/organizationManage/codeNumber/codeNumberType.vue'),
		},
		// 离职人员
		{
			path: 'dimissionList',
			name: 'dimissionList',
			component: () =>
				import('@/views/organizationManage/fabricManage/dimission/dimissionList.vue'),
		},
		// 人员变更管理
		{
			path: 'changeManageList',
			name: 'changeManageList',
			component: () =>
				import('@/views/organizationManage/fabricManage/changeManage/changeManageList.vue'),
		},
		{
			path: 'userCenter',
			name: 'userCenter',
			component: () => import('@/views/organizationManage/fabricManage/userCenter/index.vue'),
		},
		// 组织机构列表
		{
			path: 'organizationList',
			name: 'organizationList',
			component: () =>
				import('@/views/organizationManage/fabricManage/organization/organizationList.vue'),
		},
		// 资源管理
		{
			path: 'resourceManageList',
			name: 'resourceManageList',
			component: () =>
				import('@/views/organizationManage/resourceManage/resourceManageList.vue'),
		},
		// 权限管理
		{
			path: 'authManageList',
			name: 'authManageList',
			component: () => import('@/views/organizationManage/authManage/authManageList.vue'),
		},
		// 版本控制
		{
			path: 'versionList',
			name: 'versionList',
			component: () =>
				import('@/views/organizationManage/appManage/versionList/versionList.vue'),
		},
		// 待办模板列表
		{
			path: 'backlogTemplateList',
			name: 'backlogTemplateList',
			component: () =>
				import('@/views/organizationManage/backlogManage/backlogTemplate/index.vue'),
		},
		// 通算列表
		{
			path: 'commonCalculate',
			name: 'commonCalculate',
			component: () =>
				import('@/views/organizationManage/calculateManage/commonCalculate/index.vue'),
		},
		// 电子签模板
		{
			path: 'electronicTemplate',
			name: 'electronicTemplate',
			component: () => import('@/views/organizationManage/template/electronic/index.vue'),
		},
	],
}
