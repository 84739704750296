import crmHistoricalRouter from './historical.router'
import crmAuthorizationRouter from './authorization.router'
import crmCustomerRouter from './customer.router'
import crmProjectRouter from './project.router'
import businessTransferRouter from './businessTransfer.router'
import contractRouter from './contract.router'
import visitContractRouter from './visit.router'
import businessContractRouter from './business.router'

export default [
	...crmHistoricalRouter,
	...crmAuthorizationRouter,
	...crmCustomerRouter,
	...crmProjectRouter,
	...businessContractRouter,
	...businessTransferRouter,
	...contractRouter,
	...visitContractRouter,
]
