export default [
	// 配件库存 -- 详情
	{
		path: 'accessoryInventoryDetail',
		name: 'accessoryInventoryDetail',
		component: () =>
			import(
				'@/views/propertyManage/warehouseManage/accessoryInventory/childPage/detail.vue'
			),
	},
	// 低值易耗品 -- 详情
	{
		path: 'lowGoodsDetail',
		name: 'lowGoodsDetail',
		component: () =>
			import('@/views/propertyManage/lowGoodsManage/lowGoodsAccount/childPage/detail.vue'),
	},
	// 低值易耗品 -- 新增
	{
		path: 'lowGoodsAdd',
		name: 'lowGoodsAdd',
		component: () =>
			import('@/views/propertyManage/lowGoodsManage/lowGoodsAccount/childPage/add.vue'),
	},

	// 索赔实物归还管理 -- 详情
	{
		path: 'compensateHandleDetail',
		name: 'compensateHandleDetail',
		component: () =>
			import(
				'@/views/propertyManage/compensateMange/compensateCenter/childPage/compensateHandleDetail.vue'
			),
	},
	// 索赔详情 -- 详情
	{
		path: 'compensateMangeDetail',
		name: 'compensateMangeDetail',
		component: () =>
			import(
				'@/views/propertyManage/compensateMange/compensateCenter/childPage/compensateDetail.vue'
			),
	},
	// 设备区域统计 -- 详情
	{
		path: 'equipmentStatisticsDetail',
		name: 'equipmentStatisticsDetail',
		component: () =>
			import('@/views/propertyManage/equipmentManage/equipmentStatistics/detail.vue'),
	},
	// 配件领用退回 -- 配件核销 -- 配件核销详情
	{
		path: 'partsWriteOffRecordDetail',
		name: 'partsWriteOffRecordDetail',
		component: () =>
			import(
				'@/views/propertyManage/mountingsReceiveSendBack/partsWriteOffRecord/detail.vue'
			),
	},
	// 物流管理 -- 物流需求单 -- 来源同步编辑
	{
		path: 'logisticsSyncEdit',
		name: 'logisticsSyncEdit',
		component: () =>
			import('@/views/propertyManage/logisticsManage/logistics/childPage/syncEdit.vue'),
	},
	// 物流管理 -- 物流需求单 -- 详情
	{
		path: 'logisticsDetail',
		name: 'logisticsDetail',
		component: () =>
			import('@/views/propertyManage/logisticsManage/logistics/childPage/detail.vue'),
	},
	// 物流管理 -- 物流需求单 -- 新增
	{
		path: 'addLogistics',
		name: 'addLogistics',
		component: () =>
			import('@/views/propertyManage/logisticsManage/logistics/childPage/addLogistics.vue'),
	},
	// 添加保养规则
	{
		path: 'addRules',
		name: 'addRules',
		component: () =>
			import(
				'@/views/propertyManage/equipmentMaintenance/maintainRules/compsPage/addRules.vue'
			),
	},
	// 巡检 --- 添加巡检规则
	{
		path: 'publicCheckRules',
		name: 'publicCheckRules',
		component: () =>
			import(
				'@/views/propertyManage/equipmentMaintenance/inspectionRules/compsPage/publicCheckRules.vue'
			),
	},
	// 巡检 --- 巡检详情
	{
		path: 'checkRulesDetail',
		name: 'checkRulesDetail',
		component: () =>
			import(
				'@/views/propertyManage/equipmentMaintenance/inspectionRules/compsPage/checkRulesDetail.vue'
			),
	},
	// 巡检记录详情
	{
		path: 'inspectionLogDetail',
		name: 'inspectionLogDetail',
		component: () =>
			import(
				'@/views/propertyManage/equipmentMaintenance/inspectionLog/compsPage/logDetail.vue'
			),
	},

	// 设备维保 >> 保养计划详情
	{
		path: 'scheduleDetails',
		name: 'scheduleDetails',
		component: () =>
			import(
				'@/views/propertyManage/equipmentMaintenance/maintenanceSchedule/scheduleDetails.vue'
			),
	},
	// 设备报停详情
	{
		path: 'stopReportDetail',
		name: 'stopReportDetail',
		component: () =>
			import('@/views/propertyManage/equipmentManage/stopReport/detail/index.vue'),
	},
	// 设备报停延期详情
	{
		path: 'stopReportDelayDetail',
		name: 'stopReportDelayDetail',
		component: () =>
			import(
				'@/views/propertyManage/equipmentManage/stopReport/detail/tabs/delay/detail.vue'
			),
	},
	// 设备报停恢复详情
	{
		path: 'stopReportRecoveryDetail',
		name: 'stopReportRecoveryDetail',
		component: () =>
			import(
				'@/views/propertyManage/equipmentManage/stopReport/detail/tabs/recovery/detail.vue'
			),
	},
	//锁机详情
	{
		path: 'lockDetail',
		name: 'lockDetail',
		component: () =>
			import('@/views/propertyManage/equipmentManage/lockMachine/lock/lockDetail.vue'),
	},
	//解机详情
	{
		path: 'unLockDetail',
		name: 'unLockDetail',
		component: () =>
			import('@/views/propertyManage/equipmentManage/lockMachine/unLock/unLockDetail.vue'),
	},

	// //订单360
	// {
	// 	path: 'infoOrder',
	// 	name: 'infoOrder',
	// 	component: () => import('@/views/crmManage/orderManage/infoOrder.vue'),
	// },
	//设备清单详情页 -hh
	{
		path: 'InsuranceDetails',
		name: 'InsuranceDetails',
		component: () => import('@/views/propertyManage/equipmentList/InsuranceDetails.vue'),
	},
	// 设备360  -hh
	{
		path: 'equipmentGirth',
		name: 'equipmentGirth',
		component: () =>
			import('@/views/propertyManage/equipmentManage/equipment360/equipment360.vue'),
	},
	// 设备配件 -hh
	{
		path: 'equipmentAccessories',
		name: 'equipmentAccessories',
		component: () =>
			import(
				'@/views/propertyManage/equipmentList/equipmentAccessories/equipmentAccessories.vue'
			),
	},
	// 个人库存详情
	{
		path: 'detInvertory',
		name: 'detInvertory',
		component: () =>
			import(
				'@/views/propertyManage/mountingsReceiveSendBack/personInventory/personInventoryDet.vue'
			),
	},

	// 维修中心详情
	{
		path: 'detRepairsCentre',
		name: 'detRepairsCentre',
		component: () =>
			import('@/views/propertyManage/equipmentMaintenance/repairsCentre/detail.vue'),
	},
	// 报修管理详情
	{
		path: 'detRepairsMange',
		name: 'detRepairsMange',
		component: () =>
			import('@/views/propertyManage/equipmentMaintenance/repairsMange/detRepairsMange.vue'),
	},
	// 报修管理添加
	{
		path: 'addRepairsMange',
		name: 'addRepairsMange',
		component: () =>
			import('@/views/propertyManage/equipmentMaintenance/repairsMange/addRepairsMange.vue'),
	},
	// 配件领用编辑
	{
		path: 'editRecord',
		name: 'editRecord',
		component: () =>
			import(
				'@/views/propertyManage/mountingsReceiveSendBack/engineerRecord/receiveRecord/components/editRecord.vue'
			),
	},
	// 配件领用详情
	{
		path: 'detRecord',
		name: 'detRecord',
		component: () =>
			import(
				'@/views/propertyManage/mountingsReceiveSendBack/engineerRecord/receiveRecord/components/detailsRecord.vue'
			),
	},
	// 配件领用新增
	{
		path: 'addRecord',
		name: 'addRecord',
		component: () =>
			import(
				'@/views/propertyManage/mountingsReceiveSendBack/engineerRecord/receiveRecord/components/addRecord.vue'
			),
	},
	// 配件退还编辑
	{
		path: 'editSendRecord',
		name: 'editSendRecord',
		component: () =>
			import(
				'@/views/propertyManage/mountingsReceiveSendBack/engineerRecord/sendBackRecord/components/editRecord.vue'
			),
	},
	// 配件退还详情
	{
		path: 'detSendRecord',
		name: 'detSendRecord',
		component: () =>
			import(
				'@/views/propertyManage/mountingsReceiveSendBack/engineerRecord/sendBackRecord/components/detailsRecord.vue'
			),
	},
	// 配件退还新增
	{
		path: 'addSendRecord',
		name: 'addSendRecord',
		component: () =>
			import(
				'@/views/propertyManage/mountingsReceiveSendBack/engineerRecord/sendBackRecord/components/addRecord.vue'
			),
	},
	// 检修详情
	{
		path: 'inspectionReportDetails',
		name: 'inspectionReportDetails',
		component: () =>
			import(
				'@/views/propertyManage/equipmentMaintenance/inspectionReport/inspectionReportDetails.vue'
			),
	},

	//设备型号详情
	{
		path: 'modelSetDetails',
		name: 'modelSetDetails',
		component: () =>
			import(
				'@/views/propertyManage/equipmentManage/equipmentData/components/modelSetDetail.vue'
			),
	},
	//设备型号编辑
	{
		path: 'modelSetEdit',
		name: 'modelSetEdit',
		component: () =>
			import(
				'@/views/propertyManage/equipmentManage/equipmentData/components/modelSetEdit.vue'
			),
	},

	//保养报告详情
	{
		path: 'maintainDetail',
		name: 'maintainDetail',
		component: () =>
			import('@/views/propertyManage/maintainReport/components/maintainDetail.vue'),
	},

	// 出库详情
	{
		path: 'warehouseOutDetai',
		name: 'warehouseOutDetai',
		component: () => import('@/views/propertyManage/warehouseManage/warehouseOut/detail.vue'),
	},
	// 入库详情
	{
		path: 'warehouseInDetai',
		name: 'warehouseInDetai',
		component: () => import('@/views/propertyManage/warehouseManage/warehouseIn/detail.vue'),
	},
	// 新增(导入)保单
	{
		path: 'insuranceAdd',
		name: 'insuranceAdd',
		component: () =>
			import(
				'@/views/propertyManage/insuranceManage/insuranceList/components/insuranceAdd.vue'
			),
	},
	// 保单详情
	{
		path: 'insuranceDetail',
		name: 'insuranceDetail',
		component: () =>
			import('@/views/propertyManage/insuranceManage/insuranceList/components/detail.vue'),
	},
	// 物流公司详情
	{
		path: 'logisticsDetalis',
		name: 'logisticsDetalis',
		component: () =>
			import('@/views/propertyManage/logisticsManage/logisticsCompany/logisticsDetalis.vue'),
	},
	// 物流公司add
	{
		path: 'logisticsCompanyAddEdit',
		name: 'logisticsCompanyAddEdit',
		component: () =>
			import(
				'@/views/propertyManage/logisticsManage/logisticsCompany/logisticsCompanyAddEdit.vue'
			),
	},
	// 运力信息
	{
		path: 'capacityInfo',
		name: 'capacityInfo',
		component: () =>
			import('@/views/propertyManage/logisticsManage/logisticsCompany/capacityInfo.vue'),
	},
	// 新增工具划转
	{
		path: 'addTool',
		name: 'addTool',
		component: () =>
			import('@/views/propertyManage/toolManage/toolList/components/addTool.vue'),
	},
	// 工具划转详情
	{
		path: 'toolDetail',
		name: 'toolDetail',
		component: () =>
			import('@/views/propertyManage/toolManage/toolList/components/toolDetail.vue'),
	},
	// 新增设备处置
	{
		path: 'disposeAdd',
		name: 'disposeAdd',
		component: () =>
			import(
				'@/views/propertyManage/equipmentManage/equipmentDispose/components/disposeAdd.vue'
			),
	},
	// 设备处置详情
	{
		path: 'disposeDetail',
		name: 'disposeDetail',
		component: () =>
			import('@/views/propertyManage/equipmentManage/equipmentDispose/detail.vue'),
	},
	// 紧急配件处理详情
	{
		path: 'exigencyPartsDetail',
		name: 'exigencyPartsDetail',
		component: () => import('@/views/propertyManage/partsManage/exigencyParts/detail.vue'),
	},
	// 设备清单-新增设备
	{
		path: 'equipmentAdd',
		name: 'equipmentAdd',
		component: () => import('@/views/propertyManage/equipmentList/components/equipmentAdd.vue'),
	},
	// 设备清单-编辑设备
	{
		path: 'equipmentEdit',
		name: 'equipmentEdit',
		component: () =>
			import('@/views/propertyManage/equipmentList/components/equipmentEdit.vue'),
	},
]
