function toolPermission(el, permission) {
	let authArr: any = JSON.parse(localStorage.getItem('authorities') || '[]')
	if (permission && !authArr.includes(permission)) {
		el.parentNode && el.parentNode.removeChild(el) // 关键代码, 没有权限则删除元素
	}
}
export default {
	install(app) {
		app.directive('permission', {
			mounted(el, binding) {
				toolPermission(el, binding.value)
			},
			updated(el, binding) {
				toolPermission(el, binding.value)
			},
		})
	},
}
