import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import routes from './router'

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

const flowRoutes = routes => {
	let len = routes.length - 1
	let routerList = []
	routes[len].children.forEach(flow => {
		if (flow.name === 'Flow') {
			flow.children.forEach(item => {
				let router = '/flow/' + item.path
				routerList.push(router as never)
			})
		}
	})
	return routerList
}

const screenRoutes = () => {
	let routerList = []
	routes.forEach(screen => {
		if (screen.name === 'Screen' && screen.children) {
			screen.children.forEach(item => {
				let router = '/screen/' + item.path
				routerList.push(router as never)
			})
		}
	})
	return routerList
}

// 全局前置守卫
router.beforeEach((to, from, next) => {
	let flag = false

	let routesFlag = localStorage.getItem('routes')
	let routesList = []
	if (routesFlag) {
		routesList = JSON.parse(routesFlag)
		if (routesList.length > 0) {
			routesList.forEach(item => {
				if (to.path == item) flag = true
			})
		}
	}

	// 审批流程路由
	let flowRoutesList = flowRoutes(routes)
	if (flowRoutesList.length > 0) {
		flowRoutesList.forEach(item => {
			if (to.path == item) flag = true
		})
	}

	// 大屏路由
	let screenRoutesList = screenRoutes()

	if (screenRoutesList.length > 0) {
		screenRoutesList.forEach(item => {
			if (to.path == item) flag = true
		})
	}

	let token = localStorage.getItem('token')
	if (to.name !== 'Login' && !token) {
		next({ name: 'Login' })
	} else if (to.name == 'Login' && token) {
		next({ name: 'Home' })
	} else if (to.name == 'Login' && !token) {
		next()
	} else if (to.name == 'Home') {
		next()
	} else if (to.name == 'Error') {
		next()
	} else if (flag) {
		next()
	} else next({ name: 'Error' })
})

export default router
