import RouteView from '@/views/layout/routerView.vue'

export default {
	path: 'rentalService',
	name: 'rentalService',
	component: RouteView,
	children: [
		// 进场
		{
			path: 'rentalApproach',
			name: 'rentalApproach',
			component: () => import('@/views/rentalServiceManage/rentalService/approach/index.vue'),
		},
		// 退场
		{
			path: 'rentalExit',
			name: 'rentalExit',
			component: () => import('@/views/rentalServiceManage/rentalService/exit/index.vue'),
		},
		// 交接工时设置
		{
			path: 'associateWorkingHours',
			name: 'associateWorkingHours',
			component: () => import('@/views/rentalServiceManage/workingHours/index.vue'),
		},
	],
}
