export default [
	// 授权书详情
	{
		path: 'authorizationLetterDetail',
		name: 'authorizationLetterDetail',
		component: () =>
			import(
				'@/views/crmManage/authorizationLetterManage/authorizationLetterList/detail.vue'
			),
	},
	// 授权书归档详情
	{
		path: 'authorizationLetterArchiveDetail',
		name: 'authorizationLetterArchiveDetail',
		component: () =>
			import(
				'@/views/crmManage/authorizationLetterManage/authorizationLetterArchive/detail.vue'
			),
	},
	// 变更授权书归档详情
	{
		path: 'authorizationChangeArchiveDetail',
		name: 'authorizationChangeArchiveDetail',
		component: () =>
			import(
				'@/views/crmManage/authorizationLetterManage/authorizationChangeArchive/details.vue'
			),
	},
	// 变更授权书详情
	{
		path: 'authorizationChangeDetail',
		name: 'authorizationChangeDetail',
		component: () =>
			import('@/views/crmManage/authorizationLetterManage/authorizationChange/details.vue'),
	},
]
