import RouteView from '@/views/layout/routerView.vue'

export default {
	path: 'crm',
	name: 'crm',
	component: RouteView,
	children: [
		// 个人客户列表
		{
			path: 'customerList',
			name: 'customerList',
			component: () =>
				import('@/views/crmManage/customerManage/customerList/customerList.vue'),
		},
		// 企业联系人
		{
			path: 'companyLinkman',
			name: 'companyLinkman',
			component: () => import('@/views/crmManage/customerManage/companyLinkman/index.vue'),
		},
		// 安全评估记录
		{
			path: 'safetyAssessment',
			name: 'safetyAssessment',
			component: () => import('@/views/crmManage/customerManage/safetyAssessment.vue'),
		},
		// 拜访列表
		{
			path: 'visitList',
			name: 'visitList',
			component: () => import('@/views/crmManage/visitManage/visitList.vue'),
		},
		// 商机管理
		{
			path: 'businessList',
			name: 'businessList',
			component: () => import('@/views/crmManage/businessManage/businessList.vue'),
		},
		// 项目管理
		{
			path: 'projectList',
			name: 'projectList',
			component: () => import('@/views/crmManage/projectMange/projectList/projectList.vue'),
		},
		// 订单管理
		{
			path: 'orderList',
			name: 'orderList',
			component: () => import('@/views/crmManage/orderManage/orderList.vue'),
		},
		// 合同号管理
		{
			path: 'contractNumber',
			name: 'contractNumber',
			component: () =>
				import('@/views/crmManage/contractManage/contractNumber/contractNumber.vue'),
		},
		{
			path: 'newContractNumber',
			name: 'newContractNumber',
			component: () =>
				import('@/views/crmManage/contractManage/contractNumber/newContractNumber.vue'),
		},
		// 合同号使用管理
		{
			path: 'contractUseList',
			name: 'contractUseList',
			component: () =>
				import('@/views/crmManage/contractManage/contractUseManage/contractUseList.vue'),
		},
		// 合同列表
		{
			path: 'contractList',
			name: 'contractList',
			component: () =>
				import('@/views/crmManage/contractManage/contractList/contractList.vue'),
		},
		// 合同归档管理
		{
			path: 'contractArchivManage',
			name: 'contractArchivManage',
			component: () =>
				import('@/views/crmManage/contractManage/contractArchivManage/index.vue'),
		},
		// 合同号逾期管理
		{
			path: 'expectedContract',
			name: 'expectedContract',
			component: () => import('@/views/crmManage/contractManage/expectedContract/index.vue'),
		},
		// 合同号前缀管理
		{
			path: 'prefixList',
			name: 'prefixList',
			component: () => import('@/views/crmManage/contractManage/prefixManage/prefixList.vue'),
		},
		// 黑名单
		{
			path: 'blackList',
			name: 'blackList',
			component: () => import('@/views/crmManage/customerManage/blackList.vue'),
		},
		// 企业客户列表 wys自写
		{
			path: 'companyCustomer',
			name: 'companyList',
			component: () =>
				import(
					// '@/views/crmManage/customerManage/companyCustomer/companyCustomerList.vue'
					'@/views/crmManage/customerManage/companyCustomer/companyList.vue'
				),
		},
		// 合同变更记录
		{
			path: 'contractChangeList',
			name: 'contractChangeList',
			component: () =>
				import('@/views/crmManage/contractManage/contractChange/contractChangeList.vue'),
		},
		// 业务划转
		{
			path: 'businessTransfer',
			name: 'businessTransfer',
			component: () =>
				import('@/views/crmManage/businessTransfer/businessTransfer/index.vue'),
		},
		// 业务交接
		{
			path: 'businessHandover',
			name: 'businessHandover',
			component: () =>
				import('@/views/crmManage/businessTransfer/businessHandover/index.vue'),
		},
		// 异常交接
		{
			path: 'abnormalHandover',
			name: 'abnormalHandover',
			component: () =>
				import('@/views/crmManage/businessTransfer/abnormalHandover/index.vue'),
		},
		// 历史单据补录列表
		{
			path: 'historicalBillList',
			name: 'historicalBillList',
			component: () =>
				import('@/views/crmManage/historicalBill/historicalBillList/index.vue'),
		},
		// 授权书管理
		{
			path: 'authorizationLetterList',
			name: 'authorizationLetterList',
			component: () =>
				import(
					'@/views/crmManage/authorizationLetterManage/authorizationLetterList/index.vue'
				),
		},
		// 授权书归档管理
		{
			path: 'authorizationLetterArchive',
			name: 'authorizationLetterArchive',
			component: () =>
				import(
					'@/views/crmManage/authorizationLetterManage/authorizationLetterArchive/index.vue'
				),
		},
		// 授权书逾期管理
		{
			path: 'authorizationOverdue',
			name: 'authorizationOverdue',
			component: () =>
				import(
					'@/views/crmManage/authorizationLetterManage/authorizationOverdue/index.vue'
				),
		},
		// 变更授权书归档管理
		{
			path: 'authorizationChangeArchive',
			name: 'authorizationChangeArchive',
			component: () =>
				import(
					'@/views/crmManage/authorizationLetterManage/authorizationChangeArchive/index.vue'
				),
		},
		// 变更授权书逾期
		{
			path: 'authorizationChangeOverdue',
			name: 'authorizationChangeOverdue',
			component: () =>
				import(
					'@/views/crmManage/authorizationLetterManage/authorizationChangeOverdue/index.vue'
				),
		},
	],
}
