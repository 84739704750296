import RouteView from '@/views/layout/routerView.vue'

export default {
	path: 'work',
	name: 'work',
	component: RouteView,
	children: [
		// 签属管理 >> 加车
		{
			path: 'addCart',
			name: 'addCart',
			component: () => import('@/views/workManage/signedManage/addCart.vue'),
		},
		// 签属管理 >> 进场
		{
			path: 'approach',
			name: 'approach',
			component: () => import('@/views/workManage/signedManage/approach.vue'),
		},
		// 签属管理 >> 合同
		{
			path: 'contract',
			name: 'contract',
			component: () => import('@/views/workManage/signedManage/contract.vue'),
		},
		// 签属管理 >> 退场
		{
			path: 'exit',
			name: 'exit',
			component: () => import('@/views/workManage/signedManage/exit.vue'),
		},
		// 签属管理 >> 对账
		{
			path: 'statement',
			name: 'statement',
			component: () => import('@/views/workManage/signedManage/statement.vue'),
		},
		// 待办管理 ==> 全部待办
		{
			path: 'allBacklog',
			name: 'allBacklog',
			component: () => import('@/views/workManage/backlogManage/allBacklog/index.vue'),
		},
		// 待办管理 ==> 我的待办
		{
			path: 'myBacklog',
			name: 'myBacklog',
			component: () => import('@/views/workManage/backlogManage/myBacklog/index.vue'),
		},
		// 待办管理 ==> 全部已办
		{
			path: 'allHaveFinish',
			name: 'allHaveFinish',
			component: () => import('@/views/workManage/backlogManage/allHaveFinished/index.vue'),
		},
		// 待办管理 ==> 我的已办
		{
			path: 'myHaveFinish',
			name: 'myHaveFinish',
			component: () => import('@/views/workManage/backlogManage/myHaveFinished/index.vue'),
		},
		// 待办管理 ==> 我的发起
		{
			path: 'myInitiate',
			name: 'myInitiate',
			component: () => import('@/views/workManage/backlogManage/myInitiate/index.vue'),
		},
		// 待办管理 ==> 抄送我的
		{
			path: 'makeCopyMy',
			name: 'makeCopyMy',
			component: () => import('@/views/workManage/backlogManage/makeCopyMy/index.vue'),
		},
		// 文件下载中心
		{
			path: 'fileDownload',
			name: 'fileDownload',
			component: () => import('@/views/workManage/fileDownloadManage/index.vue'),
		},
	],
}
