<template>
	<a-button
		:class="{
			green: props.color === 'green',
			yellow: props.color === 'yellow',
			gray: props.color === 'gray',
		}"
		type="primary"
		:size="props.size"
		:loading="loading"
	>
		{{ props.lable }}
	</a-button>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue'
const props = defineProps({
	lable: {
		default: '',
	},
	color: {
		default: 'green',
	},
	size: {
		default: 'large',
	},
	loading: {
		type: Boolean,
		default: () => {
			return false
		},
	},
})
</script>

<style scoped lang="less">
.green {
	background-color: #45cc5b;
	border-color: #45cc5b;
	font-size: 14px;
}
.green:hover {
	background-color: rgb(86, 223, 86);
	border-color: rgb(86, 223, 86);
}
.green:disabled {
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.yellow {
	background-color: #eea413;
	border-color: #eea413;
	font-size: 14px;
}
.yellow:hover {
	background-color: #ecb64a;
	border-color: #ecb64a;
}
.yellow:disabled {
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}
.gray {
	background-color: #f2f3f5;
	border-color: #f2f3f5;
	color: #001529;
	font-size: 14px;
}
.gray:hover {
	background-color: #ebedf1;
	border-color: #ebedf1;
}
.gray:disabled {
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}
</style>
