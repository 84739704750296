import { createApp } from 'vue'
import app from './App.vue'
import router from './router'
// import store from './store'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-persistedstate-plugin'
import Antd from 'ant-design-vue'
import '@/common/css/them.less'
import '@/common/css/common.less'

// import * as Icons from '@ant-design/icons-vue'

import '@/assets/icons/index.ts'
import SvgIcon from '@/assets/icons/index.vue'
import Btn from '@/components/btn/btn.vue'
import print from 'vue3-print-nb'
import permission from '@/util/permissionUtils'

//大屏
import '@/common/css/screen.less' // 大屏公共样式
const App = createApp(app)
// 注册图标组件
// for (const i in Icons) {
// 	App.component(i, Icons[i])
// }

App.component('svg-icon', SvgIcon)
App.component('Btn', Btn)

App.use(Antd)
	.use(createPinia().use(createPersistedState()))
	.use(router)
	.use(permission)
	.use(print)
	.mount('#app')
