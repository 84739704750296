import RouteView from '@/views/layout/routerView.vue'

export default {
	path: '/screen',
	name: 'Screen',
	component: RouteView,
	children: [
		//正常大屏首页
		{
			path: 'normal',
			name: 'normal',
			component: () => import('@/views/bigScreen/normal/index.vue'),
		},
		// 静态大屏首页
		{
			path: 'static',
			name: 'static',
			component: () => import('@/views/bigScreen/static/index.vue'),
		},
		// 设备360大屏
		{
			path: 'equipmentScreen',
			name: 'equipmentScreen',
			component: () => import('@/views/bigScreen/equipmentScreen/index.vue'),
		},
		// 高空事业部大屏
		{
			path: 'highWorkBigScreen',
			name: 'highWorkBigScreen',
			component: () => import('@/views/bigScreen/highWorkBigScreen/index.vue'),
		},
	],
}
