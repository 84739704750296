export default [
	// 业务划转发起
	{
		path: 'initiateTransfer',
		name: 'initiateTransfer',
		component: () =>
			import('@/views/crmManage/businessTransfer/businessTransfer/initiateTransfer.vue'),
	},
	// 业务划转详情
	{
		path: 'initiateTransferDetail',
		name: 'initiateTransferDetail',
		component: () =>
			import(
				'@/views/crmManage/businessTransfer/businessTransfer/initiateTransferDetail.vue'
			),
	},
	// 业务交接详情
	{
		path: 'businessHandoverDetail',
		name: 'businessHandoverDetail',
		component: () =>
			import(
				'@/views/crmManage/businessTransfer/businessHandover/businessHandoverDetail.vue'
			),
	},
]
