import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
	state: () => ({
		exclude: [],
		authorities: [], // 账户权限标识
		showToolTip: true,
	}),

	actions: {
		curExclude(data) {
			this.exclude = data
		},
	},
})
export const useScreenStore = defineStore('screen', {
	state: () => ({
		highWorkScreenTheme: 'dark', // 高空事业部大屏主题
	}),
	actions: {
		changeTheme(data) {
			this.highWorkScreenTheme = data
		},
	},
})
