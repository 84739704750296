export default [
	// 企业联系人详情
	{
		path: 'companyLinkmanDetail',
		name: 'companyLinkmanDetail',
		component: () => import('@/views/crmManage/customerManage/companyLinkman/detail.vue'),
	},
	// 个人客户详情
	{
		path: 'detailsCustomeragain',
		name: 'detailsCustomeragain',
		component: () =>
			import('@/views/crmManage/customerManage/customerList/detailsCustomeragain.vue'),
	},
	// 企业客户详情
	{
		path: 'companyCustomerDetial',
		name: 'companyCustomerDetial',
		component: () =>
			import('@/views/crmManage/customerManage/companyCustomer/companyCustomerDetial.vue'),
	},
]
