export default [
	//订单详情页
	{
		path: 'detailsOrder',
		name: 'detailsOrder',
		component: () => import('@/views/crmManage/orderManage/detailsOrder.vue'),
	},
	// 合同主体变更审批
	{
		path: 'changeDerails',
		name: 'changeDerails',
		component: () =>
			import(
				'@/views/crmManage/contractManage/contractChange/changeDetails/changeDerails.vue'
			),
	},
	{
		path: 'investorExamine', //授权人变更审批
		name: 'investorExamine',
		component: () =>
			import('@/views/crmManage/contractManage/contractChange/investorExamine.vue'),
	},
	{
		path: 'addCarExamine', //加车审批
		name: 'addCarExamine',
		component: () =>
			import('@/views/crmManage/contractManage/contractChange/addCarExamine.vue'),
	},
	{
		path: 'numberExamine', //合同号审批
		name: 'numberExamine',
		component: () =>
			import('@/views/crmManage/contractManage/contractChange/numberExamine.vue'),
	},
	{
		path: 'contractReview', //合同审批
		name: 'contractReview',
		component: () => import('@/views/crmManage/contractManage/contractList/contractReview.vue'),
	},

	{
		path: 'contractDetail', //合同详情
		name: 'contractDetail',
		component: () => import('@/views/crmManage/contractManage/contractList/contractDetail.vue'),
	},
	{
		path: 'contractChangeSub', //合同主体变更提交页面
		name: 'contractChangeSub',
		component: () =>
			import('@/views/crmManage/contractManage/contractList/contractChangeSub.vue'),
	},

	{
		path: 'eleArchive', //电子归档
		name: 'eleArchive',
		component: () => import('@/views/crmManage/contractManage/contractList/eleArchive.vue'),
	},

	{
		path: 'contractPaperArchive', //合同纸质归档
		name: 'contractPaperArchive',
		component: () =>
			import('@/views/crmManage/contractManage/contractList/contractPaperArchive.vue'),
	},

	{
		path: 'useExpectedPaper', //合同使用，合同逾期归档/详情
		name: 'useExpectedPaper',
		component: () =>
			import('@/views/crmManage/contractManage/useExpectedPaper/useExpectedPaper.vue'),
	},
]
