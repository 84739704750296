import { RouteRecordRaw } from 'vue-router'
// const RouteView = () => import('@/views/layout/routerView.vue')
import screenRouters from './screen.router'
import sysRouters from './sys.router'
import crmRouters from './crm.router'
import financeRouters from './finance.router'
import assetsRouters from './assets.router'
import workRouters from './work.router'
import rentalRouters from './rental.router'
import statisticsRouters from './statistics.router'
import detailsRouters from './details'

// import { h, resolveComponent } from 'vue'
// const RouteView = {
// 	render: () => h(resolveComponent('router-view')),
// }
const routes: Array<RouteRecordRaw> = [
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/user/login.vue'),
	},
	{
		path: '/404',
		name: 'Error',
		component: () => import('@/views/layout/404.vue'),
	},
	screenRouters,
	{
		path: '/',
		name: 'Layout',
		component: () => import('@/views/layout/basicLayout.vue'),
		redirect: '/login',
		children: [
			{
				path: 'home',
				name: 'Home',
				component: () => import('@/views/layout/home.vue'),
			},
			// 系统管理
			sysRouters,
			// crm 管理
			crmRouters,
			// 财务管理
			financeRouters,
			// 资产管理
			assetsRouters,
			// 工作台
			workRouters,
			// 详情
			detailsRouters,
			// 租赁服务
			rentalRouters,
			// 统计
			statisticsRouters,
		],
	},
]
export default routes
