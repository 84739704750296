import RouteView from '@/views/layout/routerView.vue'

export default {
	path: 'finance',
	name: 'finance',
	component: RouteView,
	children: [
		// 结算单
		{
			path: 'summary', // 汇总结算管理
			name: 'summary',
			component: () => import('@/views/financeManage/statementManage/summary/summary.vue'),
		},
		{
			path: 'bicycle', // 单车结算单管理
			name: 'bicycle',
			component: () => import('@/views/financeManage/statementManage/bicycle/bicycle.vue'),
		},
		// 对账单
		{
			path: 'statementList', // 对账单管理
			name: 'statementList',
			component: () => import('@/views/financeManage/statement/statementList.vue'),
		},
		// 收款管理
		{
			path: 'collectionManage', // 客户收款管理
			name: 'collectionManage',
			component: () => import('@/views/financeManage/proceedManage/collectionManage.vue'),
		},
		{
			path: 'collectionFlow', // 客户收款流水
			name: 'collectionFlow',
			component: () => import('@/views/financeManage/proceedManage/collectionFlow.vue'),
		},
		{
			path: 'getMoney', // 银联收款对账管理
			name: 'getMoney',
			component: () => import('@/views/financeManage/proceedManage/getMoney.vue'),
		},
		{
			path: 'cancleList', // 核销明细列表
			name: 'cancleList',
			component: () => import('@/views/financeManage/proceedManage/cancleList.vue'),
		},
		// 退款管理
		{
			path: 'refundManage', //退款管理
			name: 'refundManage',
			component: () => import('@/views/financeManage/refundManage/refundManage.vue'),
		},
		// 应收管理  -hh
		{
			path: 'receivableManage',
			name: 'receivableManage',
			component: () => import('@/views/financeManage/receivableManage/receivableManage.vue'),
		},
		// 索赔减免
		{
			path: 'claimReductionManage',
			name: 'claimReductionManage',
			component: () => import('@/views/financeManage/claimReductionManage/index.vue'),
		},

		// 维修减免  -hh
		{
			path: 'maintenanceRelief',
			name: 'maintenanceRelief',
			component: () =>
				import('@/views/financeManage/maintenanceRelief/maintenanceRelief.vue'),
		},

		// 履行结算单管理  -hh
		{
			path: 'performSettlement',
			name: 'performSettlement',
			component: () =>
				import(
					'@/views/financeManage/statementManage/performSettlement/performSettlement.vue'
				),
		},
		// 履行结算单管理详情  -hh
		{
			path: 'performDetails',
			name: 'performDetails',
			component: () =>
				import(
					'@/views/financeManage/statementManage/performSettlement/performDetails.vue'
				),
		},

		// 诉讼管理
		{
			path: 'litigation',
			name: 'litigation',
			component: () => import('@/views/financeManage/litigationManage/index.vue'),
		},
		// 催收管理
		{
			path: 'urgentRecallManage',
			name: 'urgentRecallManage',
			component: () =>
				import('@/views/financeManage/urgentRecallManage/urgentRecallManage/index.vue'),
		},
		// 强退管理
		{
			path: 'strongLogoutManage',
			name: 'strongLogoutManage',
			component: () =>
				import('@/views/financeManage/urgentRecallManage/strongLogoutManage/index.vue'),
		},
		// 逾期锁机管理
		{
			path: 'overdueLockManage',
			name: 'overdueLockManage',
			component: () =>
				import('@/views/financeManage/urgentRecallManage/overdueLockManage/index.vue'),
		},
		// 延期付款
		{
			path: 'delayPayManage',
			name: 'delayPayManage',
			component: () =>
				import('@/views/financeManage/urgentRecallManage/delayPayManage/index.vue'),
		},
		// 主体变更调整记录
		{
			path: 'adjustingEntry',
			name: 'adjustingEntry',
			component: () => import('@/views/financeManage/subjectChange/adjustingEntry/index.vue'),
		},
		// 主体变更收退款调整
		{
			path: 'collectionAndRefundAdjustment',
			name: 'collectionAndRefundAdjustment',
			component: () =>
				import(
					'@/views/financeManage/subjectChange/collectionAndRefundAdjustment/index.vue'
				),
		},
		//  收据打印
		{
			path: 'receiptPrint',
			name: 'receiptPrint',
			component: () => import('@/views/financeManage/proceedManage/receiptPrint/index.vue'),
		},
		//  业务划转调整记录
		{
			path: 'operationTransfer',
			name: 'operationTransfer',
			component: () =>
				import('@/views/financeManage/subjectChange/operationTransfer/index.vue'),
		},
		//  业务划转调整记录
		{
			path: 'operationTransferRefund',
			name: 'operationTransferRefund',
			component: () =>
				import('@/views/financeManage/subjectChange/operationTransferRefund/index.vue'),
		},
		// 案件列表
		{
			path: 'caseList',
			name: 'caseList',
			component: () => import('@/views/financeManage/urgentRecallManage/caseList/index.vue'),
		},
	],
}
