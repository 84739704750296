<template>
	<a-config-provider :locale="locale">
		<div id="tg-body">
			<router-view />
		</div>
	</a-config-provider>
</template>

<script lang="ts" setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { reactive } from 'vue'

dayjs.locale('zh-cn')
const locale = reactive(zhCN)
</script>

<style lang="less">
#tg-body {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	font-size: 14px;
}

/****** 滚动条样式 ******/
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: #f7f8fa;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.25);
	border-radius: 8px;
}

/****** 菜单样式 ******/
.ant-menu-item-selected {
	color: #fff !important;
	background-color: rgba(0, 21, 41, 0.5) !important;
}
.ant-menu-vertical {
	.ant-menu-item {
		color: rgba(0, 0, 0, 0.45);
	}
	.ant-menu-item-only-child:hover {
		color: #001529 !important;
	}
	.ant-menu-item-selected:hover {
		color: #fff !important;
	}
}

/****** modal 弹窗样式 ******/
.ant-modal-footer {
	border-top: none !important;
	padding: 0 20px 20px !important;
}
.ant-modal-body {
	padding: 20px !important;
}
</style>
